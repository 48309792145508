.table > :not(caption) > * > * {
    padding: 0.75rem 0.75rem;
    color: #495057;
    background-color: #fff !important;
    border-bottom-width: 1px;
}

.table > :not(caption) > * > .subknowledge-header {
    padding: 0.75rem 0.75rem;
    color: #495057;
    background-color: #e1e6ff !important;
    border-bottom-width: 1px;
}

.pagination {
	--bs-pagination-border-radius: 0rem !important;
	--bs-pagination-color: #74788d !important;
	--bs-pagination-bg: #fff !important;
	--bs-border-width: 1px !important;
}

.pagination li.previous {
	margin-top: 0.1px;
    border: 1px solid lightgrey;
}

.pagination li.next {
	margin-top: 0.1px;
    border: 1px solid lightgrey;
}

.pagination li.previous a.page-link,
.pagination li.next a.page-link {
	border: 0px solid white;
}

.pagination li.page-item:not(.previous):not(.next) {
  margin-top: 0.2px;
}
