@keyframes appearAndMoveUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rectangle {
  opacity: 0;
  animation-name: appearAndMoveUp;
  animation-duration: 2s;
  animation-fill-mode: forwards; /* Use "forwards" to retain the state at the end of the animation */
}