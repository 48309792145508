.modal-content {
	border-radius: 0.8rem;
}

.drink-option {
	height: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 2rem !important;
  border-radius: 0.5rem;
}

.drink-option:hover{
	background: #c8e6ff;
}