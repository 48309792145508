.text-segment {
  cursor: pointer;
  padding: 2px;
  position: relative; /* Make the parent relative */
}

.text-segment:hover {
  background-color: lightblue;
}

.hidden-text {
  position: absolute; /* Position it absolutely */
  top: 0;
  left: 0;
  white-space: nowrap;
  opacity: 0; /* Make it invisible */
  pointer-events: none; /* Prevent it from receiving any mouse events */
}