
.card-prompt {
	margin-bottom: 1rem;
	background: #b4dce3;
}
.card-body {
	border-radius: 0rem 0rem 1rem 1rem;
}
.card-body1 {
  background: #b4dce3;
}
.card-body2 {
  background: #fcefb3;
}
.card-body3 {
  background: #dacac2;
}
.card-body4 {
  background: #f7c5c3;
}
.card-body5 {
  background: #dceec1;
}
.card-body6 {
  background: #b2e4f7;
}

.anywrap {
  word-wrap: anywhere;
  white-space: normal;
}

/* For extra large devices (xl - screens ≥1200px) */
@media (min-width: 1200px) {
  .welcome-purple{
    min-height: 8rem;
  }
}

/* For large devices (lg - screens ≥992px and <1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .welcome-purple{
    min-height: 14rem;
  }
}

/* For medium devices (md - screens ≥768px and <992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .welcome-purple{
    min-height: 14rem;
  }
}

/* For small devices (sm - screens ≥576px and <768px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .welcome-purple{
    min-height: 14rem;
  }
}

/* For xs devices (xs - screens <576) */
@media (max-width: 575.98px) {
  .welcome-purple{
    min-height: 14rem;
  }
}

.arrow-icon {
  display: inline-block;
  color: green; /* Set the color of the arrow */
}

/* Define the keyframes for the animation */
@keyframes shiftRight {
  0%, 100% {
    transform: translateX(0); /* Start and end at original position */
  }
  50% {
    transform: translateX(10px); /* Shift right by 10px */
  }
}

/* Apply the animation to your icon */
.arrow-icon i {
  animation: shiftRight 2s ease-in-out infinite;
}