
.title-text {
  color: black !important;
}

.g_id_signin > div > div:first-child{
  display: none;
}

.g_id_signin > div > div:last-child{
  display: none;
}