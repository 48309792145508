.draggable-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
}

.item-logo {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: #eee; /* Placeholder color, replace with actual image */
  margin-right: 10px;
  object-fit: cover;
}

.item-name {
  flex-grow: 1;
}

.delete-item-btn {
  margin-left: auto; /* Pushes the button to the right */
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 0.5rem;
  /* Additional styling as needed */
}