.sticky-sidebar-container {
  position: relative; /* This ensures the sticky positioning works as expected */
}

.sticky-sidebar {
  position: -webkit-sticky; /* For Safari */
  position: sticky !important;
  top: 0; /* Adjust this value based on your header's height if needed */
  height: 100vh; /* Optional: might help with longer content, but adjust as needed */
  overflow-y: auto; /* Makes the sidebar scrollable if the content is too long */
}

/* Default style for .right-sidebar */
.right-sidebar {
  width: 25% !important;
}

.scrollable-div {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}
.scrollable-div::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

/* Override style for .right-sidebar when viewport width is at least 992px */
@media (min-width: 992px) {
  .right-sidebar {
    width: 20% !important;
  }
}

.positive {
  color: green; /* Green text indicates additions */
  background-color: #d4f8d4; /* Light green background for added text */
}

.negative {
  color: red; /* Red text indicates deletions */
  text-decoration: line-through; /* Strikethrough for deleted text */
  background-color: #f8d4d4; /* Light red background for deleted text */
}

.translate-row {
	border: 1px solid transparent;
	border-bottom: none;
}

.translate-row-selected {
	background: aliceblue;
	border: 1px solid #a5c2dc;
}

.translate-row:hover{
	background: aliceblue;
	border: 1px solid #a5c2dc;
	/*cursor: pointer;*/
}






