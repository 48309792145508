
.profile-pic {
	width: 10rem; /* or any other size */
	height: 10rem; /* should be the same as width to create a circle */
	border-radius: 50%; /* this makes it round */
	object-fit: cover; /* this ensures that the image covers the area and is not stretched */
	display: block; /* replace with flex or grid if needed */
	margin: 0 auto; /* this centers the image in its container if it's a block element */
	overflow: hidden; /* this ensures that the image does not break the round shape */
}

.avatar-bigger {
	height: 5rem !important;
	width: 5rem !important;
}