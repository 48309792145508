/* Chatbot.css */

.chatbot-container {
  position: fixed; /* Fixed positioning relative to the viewport */
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure it's above other elements */
}

.chat-window {

  position: fixed; /* Fixed positioning for chat window as well */
  bottom: 0px; /* Adjust this value as needed */
  right: 120px;

  min-width: 350px;
  max-width: 350px;
  min-height: 400px;
  display: flex;
  flex-direction: column;

  border-radius: 10px 10px 0px 0px;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);

  border: 1px solid #ccc;
  background-color: #fff;

  display: none; /* Hidden by default */
  z-index: 999; /* Just below the button to avoid overlap issues */
}

.chat-header {
  background-color: #00AABB;
  color: #FFFFFF;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.5rem;
}

.chat-header-text{
	line-height: 1.6;
    margin: 0rem 1rem 0rem 0.7rem;
}

.close-chat {
  background: none;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 2rem;
}

.chat-messages {
  padding: 10px;
  overflow-y: scroll;
  height: 400px;
  flex-grow: 1;
  background: #eee;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-input-104 {
  border-top: 1px solid #dddddd;
  padding: 10px;
  background-color: #fff !important;
  display: flex;
}

.chat-input-104 textarea {
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  background: #eee;
  padding: 10px;
  width: calc(100% - 60px);
  margin-right: 10px;
}

.send-message {
  background-color: #FFF;
  color: #00AABB;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 0rem 1rem;
  align-self: center;
  font-size: 1.5rem;
}

.chat-window.open {
  display: flex;
}

.chatbot-img {
	height: 5rem;
}

.chatbot-button {
  position: fixed;
  bottom: 20px; /* Distance from bottom of the viewport */
  right: 20px; /* Distance from right of the viewport */
  z-index: 1000; /* High z-index to keep it above other content */
  background: transparent;
  border: none;
}

.message {
  max-width: 80%;
  margin: 5px;
  border-radius: 20px;
  white-space: pre-line;
  padding: 16px;
  padding-right: 11px;
  min-width: 60%;
}

/* Styles for each message row */
.dialog-row {
  min-width: 60%;
  max-width: 100%;

  display: flex;
  align-items: flex-end; /* Aligns the timer and dialog box vertically */
  justify-content: flex-end; /* For received messages, push content to the right */
  
  margin: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
  white-space: pre-line;

}

/* Styles for the timer */
.timer-sent {
  min-width: 50px; /* Adjust as needed to prevent time from collapsing */
  text-align: center;
  font-size: 0.6rem; /* Adjust as needed */
  margin: 0 8px 5px 8px;
  color: #6a6a6a;
}

.timer-received {
  min-width: 50px; /* Adjust as needed to prevent time from collapsing */
  text-align: center;
  font-size: 0.6rem; /* Adjust as needed */
  margin: 0 8px 5px 8px;
  color: #6a6a6a;
}

/* For sent messages, reverse the order so that the timer is on the left */
.sent-side {
  align-self: flex-end;
}

.received-side {
  align-self: flex-start;
  justify-content: flex-start;
}

/* Styles for the dialog box */
.sent-side .dialog-box {
  background-color: #e6f9fa; /* Example color - change as needed */
  padding: 10px;
  border-radius: 4px;
  max-width: 70%;
}

.received-side .dialog-box {
  background-color: #fff; /* Example color - change as needed */
  padding: 10px;
  border-radius: 4px;
}

/* Styles for the text inside the dialog box */
.title {
  font-size: 0.8rem; /* Adjust as needed */
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 2px;
  background-color: #333;
  border-radius: 50%;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: calc(0.2s * var(--dot-index));
}

@keyframes dotFlashing {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}




